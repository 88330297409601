import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { UnleashService } from './unleash.service';
import CFG from '../config/app-config.json';
import { Subscription, interval } from 'rxjs';

export enum LogLevel {
	INFO = 'info',
	DEBUG = 'debug',
	WARN = 'warn',
	ERROR = 'error',
}

export type Log = {
	level: LogLevel;
	message: string;
	params: any;
};

export type LogWithTimestamp = Log & { timestamp: string };

@Injectable({
	providedIn: 'root',
})
export class LoggerService implements OnDestroy {
	private onDeviceLogs: LogWithTimestamp[] = [];
	private readonly onDeviceLogsSize = 40;

	private buffer: Log[] = [];
	private readonly bufferSize = 10; // Number of logs to buffer before sending
	public bufferTime = 4000; // Time interval (in milliseconds) for sending buffered logs
	private readonly localStorageKey = 'buffered-logs';
	interval: Subscription;
	saveLogs = false;

	constructor(private logger: NGXLogger, private http: HttpClient, private unleashService: UnleashService) {
		const savedLogs = localStorage.getItem(this.localStorageKey);

		if (savedLogs) {
			this.buffer = JSON.parse(savedLogs);
		}

		this.unleashService.isEnabled$('YO-61650-healthee-app-logger').subscribe((isEnabled) => {
			if (isEnabled) {
				this.saveLogs = true;
				this.scheduleBufferedLogs();
			}
		});
	}

	clearBuffer() {
		this.buffer = [];
		localStorage.removeItem(this.localStorageKey);
	}

	info(message: string, params?: any): void {
		this.log({ level: LogLevel.INFO, message, params });
	}

	debug(message: string, params?: any): void {
		this.log({ level: LogLevel.DEBUG, message, params });
	}

	warn(message: string, params?: any): void {
		this.log({ level: LogLevel.WARN, message, params });
	}

	error(message: string, params?: any): void {
		this.log({ level: LogLevel.ERROR, message, params });
	}

	public debugOnDevice(message: string, params?: any): void {
		const timestamp = new Date().toISOString();

		this.onDeviceLogs.push({ timestamp, level: LogLevel.DEBUG, message, params });

		if (this.onDeviceLogs.length > this.onDeviceLogsSize) {
			this.onDeviceLogs.shift();
		}
	}

	public hybridLog(message?: any, ...optionalParams: any[]) {
		console.log(message, ...optionalParams);
		this.debugOnDevice(message, ...optionalParams);
	}

	public getOnDeviceDebugLogs(): LogWithTimestamp[] {
		return [...this.onDeviceLogs];
	}

	public clearOnDeviceLogs() {
		this.onDeviceLogs = [];
	}

	private log(log: Log): void {
		const timestamp = new Date().toISOString();
		const location = this.getLogLocationInCode();
		if (log.params) this.logger[log.level](log.message, log.params);
		else this.logger[log.level](log.message);

		if (!this.saveLogs) return;

		const enrichedLog = { ...log, timestamp, location };
		this.buffer.push(enrichedLog);
		localStorage.setItem(this.localStorageKey, JSON.stringify(this.buffer));

		if (this.buffer.length >= this.bufferSize) {
			this.sendBufferedLogs();
		}
	}

	private sendBufferedLogs(): void {
		if (this.buffer.length === 0) {
			return;
		}

		const logsToSend = this.buffer;

		const multilineString = logsToSend.map((obj) => JSON.stringify(obj)).join('\n');

		this.clearBuffer();

		this.http.post(CFG.logDumpEndpoint, multilineString).subscribe({
			error: () => {
				this.buffer = this.buffer.concat(logsToSend);
				localStorage.setItem(this.localStorageKey, JSON.stringify(this.buffer));
			},
		});
	}

	private scheduleBufferedLogs(): void {
		this.interval = interval(this.bufferTime) // Emits a value every 2000 milliseconds (2 seconds)
			.subscribe(() => {
				if (this.buffer.length > 0) {
					this.sendBufferedLogs();
				}
			});
	}

	private getLogLocationInCode(): string {
		const error = new Error();
		const stackLines = error.stack?.split(/\r\n|\n/);
		return stackLines && stackLines.length > 4 ? stackLines[4] : '';
	}

	ngOnDestroy(): void {
		if (this.interval) {
			this.interval.unsubscribe();
		}
	}
}
