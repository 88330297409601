import { T } from '@transifex/angular';

export class HomePageLocalize {
	@T('Can you assist me with a claim review?')
	static txtHelpMeWithClaimReview: string;

	@T('Can you help me with plan selection for open enrollment?')
	static txtCanYouHelpMeWithPlanSelection: string;

	@T('Do I have coverage for weight loss related benefits?')
	static txtDoIHaveCoverageForWightLoss: string;

	@T('Do I have coverage for specific procedures?')
	static txtDoIHaveCoverageForProcedures: string;

	@T('Can you explain to me how my plan works?')
	static txtCanYouExplainHowPlanWorks: string;

	@T('What physical therapy coverage do I have?')
	static txtWhatPhysicalTherapyCoverageDoIHave: string;

	@T('Can you help me understand what coverage I have for prescription medication?')
	static txtCanYouHelpMeUnderstandCoverageForPrescriptionMedication: string;

	static getCxPredefinedMessages(): ({ [key: string]: string }) {
		return {
			// Message IDs (keys) are random and have no intrinsic meaning.
			// They are used by CX and included in their deep-links.
			// (Deliberately non-sequential to prevent tampering or predictable patterns.)
			'e19f40': HomePageLocalize.txtHelpMeWithClaimReview,
			'8a2863': HomePageLocalize.txtCanYouHelpMeWithPlanSelection,
			'f56aa0': HomePageLocalize.txtDoIHaveCoverageForWightLoss,
			'8c4dfa': HomePageLocalize.txtDoIHaveCoverageForProcedures,
			'75009c': HomePageLocalize.txtCanYouExplainHowPlanWorks,
			'9a626e': HomePageLocalize.txtWhatPhysicalTherapyCoverageDoIHave,
			'e8e5ff': HomePageLocalize.txtCanYouHelpMeUnderstandCoverageForPrescriptionMedication,
		}
	}
}
