import { Injectable } from '@angular/core';

import { Observable, combineLatest, filter, map, switchMap, take, from } from 'rxjs';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UIService } from '../services/ui.service';
import {
	PCT_2_TRINET_PATH,
	PLAN_SELECTION_CLOSED_PATH,
	PLAN_SELECTION_ONLY_PATH,
	PLAN_SELECTION_PATH,
} from '../modules/main-layout/main-layout.constants';
import { PlanSelectionStoreService } from '../services/stores/plan-selection-store/plan-selection-store.service';
import CFG from 'src/app/config/app-config.json';
import { UnleashService } from '../services/unleash.service';
import { RxjsUtils } from '../utils/rxjs';
import { UserService } from '../services/user.service';
import { environment } from 'src/environments/environment';

export const PCT_2_FF = 'PCT-2-redirect';

@Injectable({
	providedIn: 'root',
})
export class PCTOnlyGuard implements CanActivate {
	public isPlanSelectionOpen$: Observable<boolean> = this.planSelectionStoreService.isPlanSelectionEnabled();
	public isTrinet$ = this.planSelectionStoreService.get().pipe(
		RxjsUtils.isNotNil(),
		take(1),
		map((data) => data && data.companyData && data.companyData.isTriNet)
	);

	constructor(
		private router: Router,
		private uiService: UIService,
		private planSelectionStoreService: PlanSelectionStoreService,
		private unleashService: UnleashService,
		private userService: UserService
	) {}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return combineLatest([
			this.isPlanSelectionOpen$,
			this.uiService.PCTOnlyMode$,
			this.isTrinet$,
			this.userService.userData$,
		]).pipe(
			filter(
				([isPlanSelectionOpen, isPctOnlyMode, isTrinet, userData]) =>
					isPlanSelectionOpen !== null && isPctOnlyMode !== null && isTrinet !== null && userData !== null
			),
			switchMap(([isPlanSelectionOpen, isPctOnlyMode, isTrinet, userData]) => {
				return from(
					this.unleashService.updateContext({
						userId: userData?.uid,
						companyId: userData?.company?.id,
						emailDomain: userData?.email,
					})
				).pipe(map(() => [isPlanSelectionOpen, isPctOnlyMode, isTrinet, userData]));
			}),
			map(([isPlanSelectionOpen, isPctOnlyMode, isTrinet]) => {
                const isPCT2Enabled = this.unleashService.isEnabled(PCT_2_FF);
                if(!isTrinet) {
                    window.location.href = environment.pct2Link;
                    return false;
                } else if (isPCT2Enabled) {
					window.location.href = this.getPct2TrinetUrl();
                    return false;
				} else if (isPlanSelectionOpen) {
					if (state.url === `/${PLAN_SELECTION_CLOSED_PATH}`) {
						return this.uiService.PCTOnlyMode
							? this.router.parseUrl(PLAN_SELECTION_ONLY_PATH)
							: this.router.parseUrl(PLAN_SELECTION_PATH);
					}
					return true;
				} else if (isPctOnlyMode) {
					if (state.url !== `/${PLAN_SELECTION_CLOSED_PATH}`) {
						return this.router.parseUrl(PLAN_SELECTION_CLOSED_PATH);
					}
					return true;
				} else {
                    return this.router.createUrlTree([CFG.defaultAppRoute]);
                }

			})
		);
	}

	getPct2TrinetUrl(): string {
		const eventDate = localStorage.getItem('eventDate');
		const eventType = localStorage.getItem('eventType');
		const returnUrl = localStorage.getItem('returnUrl');
		return `${environment.pct2Link}/${PCT_2_TRINET_PATH}&eventDate=${eventDate}&eventType=${eventType}&returnUrl=${returnUrl}`;
	}
}
