<div class="appointment-confirmation-container">
	<h1>{{ 'Confirm your appointment information' | translate }}</h1>
	<div class="confirm-info">
		<div class="header-provider">
			<provider-avatar [gender]="providerData.gender" size="medium"></provider-avatar>
			<div class="info">
				<div class="title">
					{{ providerData.firstName }} {{ providerData.lastName
					}}<span *ngFor="let degree of providerData.degrees">, {{ degree }}</span>
				</div>
				<div class="sub-title">
					<span *ngFor="let specialty of providerData?.specialties">{{ specialty }}</span>
				</div>
			</div>
		</div>
		<div class="info-block">
			<div class="header">
				<h3>{{ 'Location' | translate }}</h3>
				<button class="link" (click)="goToStep.emit(flowSteps.availability)">{{ 'Edit' | translate }}</button>
			</div>
			<p>
				{{ selectedLocation?.address1
				}}{{ selectedLocation?.address2 ? ', ' + selectedLocation?.address2 : '' }},
				{{ selectedLocation?.city }}, {{ selectedLocation?.state }} {{ selectedLocation?.zipCode }}
			</p>
		</div>
		<div class="info-block">
			<div class="header">
				<h3>{{ 'Date' | translate }}</h3>
			</div>
			<p>{{ selectedSlot?.date | date : 'MMMM d' }}</p>
			<p>{{ selectedSlot?.date | date : 'shortTime' }}</p>
		</div>
	</div>

	<h2 class="">{{ 'Please review your details' | translate }}</h2>
	<div [formGroup]="userInfoForm" class="userinfo-form">
		<div class="input-with-label">
			<label for="firstName">{{ 'First Name' | translate }}</label>
			<input formControlName="firstName" id="firstName" class="fs-unmask" placeholder="e.g. John" />
		</div>
		<div class="input-with-label">
			<label for="firstName">{{ 'Last Name' | translate }}</label>
			<input formControlName="lastName" id="lastName" class="fs-unmask" placeholder="e.g. Doe" />
		</div>
		<div class="input-with-label">
			<label for="dob">{{ 'Date of Birth' | translate }}</label>
			<input formControlName="dob" id="dob" placeholder="DD/MM/YYYY" type="date" class="fs-unmask" />
		</div>
		<div class="input-with-label">
			<label for="gender">{{ 'Gender' | translate }}</label>
			<app-select-form-control
				floatLabel="never"
				id="gender"
				valueField="id"
				displayField="display"
				formControlName="gender"
				[items]="gender"
				[multi]="false"
				isFullWidth="true"
			>
			</app-select-form-control>
		</div>

		<div class="input-with-label">
			<label for="address">{{ 'Address' | translate }}</label>
			<input
				id="address"
				[class.with-error]="
					onShowError('userAddress') ||
					onShowError('userCity') ||
					onShowError('userState') ||
					onShowError('userZipcode')
				"
				matInput
				ngx-google-places-autocomplete
				type="text"
				class="fs-unmask"
				[options]="googlePlaceOptions"
				#placesRef="ngx-places"
				#locationInput
				[placeholder]="'e.g. “99 Fifth Avenue, Pelham, NY, USA”' | translate"
				(onAddressChange)="setAddressFields($event)"
			/>
			<div
				*ngIf="
					onShowError('userAddress') ||
					onShowError('userCity') ||
					onShowError('userState') ||
					onShowError('userZipcode')
				"
				class="error"
			>
				<ripple-icon name="close" color="red"></ripple-icon>
				<mat-error>{{
					'Please enter street name and number and select from the available options.' | translate
				}}</mat-error>
			</div>
		</div>
		<div class="input-with-label">
			<label for="phoneNumber">{{ 'Phone Number' | translate }}</label>
			<input
				formControlName="phoneNumber"
				type="tel"
				autocomplete="off"
				id="phoneNumber"
				[placeholder]="'e.g. “(555) 555-555”' | translate"
				pattern="^\(\d{3}\) \d{3}-\d{4}$"
				maxLength="14"
				appPhoneMask
			/>
			<div *ngIf="onShowError('phoneNumber')" class="error">
				<ripple-icon name="close" color="red"></ripple-icon>
				<mat-error>{{ 'Please enter a valid US phone number.' | translate }}</mat-error>
			</div>
		</div>
		<div class="input-with-label">
			<label for="email">{{ 'Email Address' | translate }}</label>
			<input formControlName="email" id="email" />
			<div *ngIf="onShowError('email')" class="error">
				<ripple-icon name="close" color="red"></ripple-icon>
				<mat-error>{{ 'Please enter a valid email.' | translate }}</mat-error>
			</div>
		</div>
	</div>
</div>
