<div class="top-searched-container">
	<div class="top-searched-container-header">
		<h2 class="title">{{ 'Explore top-rated providers' | translate }}</h2>
		<div class="filters-button">
			<ripple-button
				leadingIcon="filters"
				[label]="'Filters' | translate"
				iconSize="size-20"
				[withoutBorder]="true"
				size="lg"
				[isCurrentColor]="true"
				[isFullWidth]="true"
				hierarchy="tertiary"
				(onClick)="onClickFiltersHandler()"
			></ripple-button>
		</div>
	</div>
	<div class="top-searched-list-wrapper">
		<div class="top-searched-list">
			<ripple-button
				*ngFor="let service of topSearchedServices"
				[label]="service.text"
				[isCurrentColor]="!service.selected"
				[hierarchy]="service.selected ? 'secondary' : 'tertiary'"
				(onClick)="onClick(service)"
				[isDisabled]="isLoading$ | async"
			></ripple-button>
		</div>
	</div>
</div>
