import { Injectable } from '@angular/core';

import { Observable, combineLatest, map, take } from 'rxjs';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { PlanSelectionStoreService } from '../services/stores/plan-selection-store/plan-selection-store.service';
import { RxjsUtils } from '../utils/rxjs';
import { UnleashService } from '../services/unleash.service';
import { PCT_2_FF } from './pct-only.guard';
import { PCT_2_TRINET_PATH, PLAN_SELECTION_ONLY_PATH } from '../modules/main-layout/main-layout.constants';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PlanSelectionGuard implements CanActivate {
	private isPCT2Enabled$ = this.unleashService.isEnabled$(PCT_2_FF);
	public isTrinet$ = this.planSelectionStoreService.get().pipe(
		RxjsUtils.isNotNil(),
		take(1),
		map((data) => data.companyData?.isTriNet)
	);

	constructor(
		private router: Router,
		private planSelectionStoreService: PlanSelectionStoreService,
		private unleashService: UnleashService
	) {}

	public canActivate(): Observable<boolean | UrlTree> {
		return combineLatest([
			this.isPCT2Enabled$,
			this.isTrinet$,
		]).pipe(
			map(([isPCT2Enabled, isTrinet]) => {
                if(!isTrinet) {
                    window.location.href = environment.pct2Link;
                    return false;
                } else if (isPCT2Enabled) {
                    const trinetRedirectPath = `${environment.pct2Link}/${PCT_2_TRINET_PATH}` ;
                    window.location.href = trinetRedirectPath;
                    return false;
				} else {
                    return this.router.createUrlTree([`/${PLAN_SELECTION_ONLY_PATH}`]);
                }
			}),
			take(1)
		);
	}
}
