import { TrackingService } from './tracking.service';
import { randomString } from '../utils/utils';
import { Injectable } from '@angular/core';
import { captureExceptionSentry } from '../utils/sentry.utils';
import { IntercomService } from './intercom.service';

const TAG = 'UserSessionService';
export const USER_SESSION_STORAGE_KEY = 'lt:session:id';

@Injectable({ providedIn: 'root' })
export class UserSessionService {
	constructor(private trackingService: TrackingService, private intercomService: IntercomService) {}

	/**
	 * The method grab a session id from the sessionStorage or generate a new id.
	 * It than send a "Session Start/Continue" with the new/old session id and user data.
	 * @param source - where the set is starting from
	 * @param userData - user information as received from the login (can be null)
	 */
	public startUserSession(source: string, userData: object = null) {
		try {
			let sessionId;
			let event = 'Session Start';

			if (sessionStorage) {
				sessionId = sessionStorage.getItem(USER_SESSION_STORAGE_KEY);
			}

			if (sessionId) {
				event = 'Session Continue';
			} else {
				sessionId = randomString(16);
				if (sessionStorage) {
					sessionStorage.setItem(USER_SESSION_STORAGE_KEY, sessionId);
				}
			}

			const logData = { sessionId, source };
			if (userData) {
				if (userData?.['company']?.['id']) {
					logData['companyId'] = userData?.['company']?.['id'];
				}
			}
			this.trackingService.trackClientEvent(event, logData);
			
			// This check is to prevent duplicate session initiated events on login case.
			// It will send one after login as expected.
			if (logData.source !== 'login') {
				this.intercomService.sendClientEvent('Healthee App - Session Initiated', { version: 1 });
			}
		} catch (e) {
			captureExceptionSentry(e, TAG);
		}
	}

	public endUserSession() {
		if (sessionStorage) {
			sessionStorage.removeItem(USER_SESSION_STORAGE_KEY);
		}
	}
}
