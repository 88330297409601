import { Component, OnInit, ViewChild, ElementRef, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { EMPTY, Observable, Subscription, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { T } from '@transifex/angular';
import { AppLanguage, LanguageService } from 'src/app/services/language.service';

import CFG from '../../../../config/app-config.json';
import { resolveHost, useIonicFile } from '../../../../utils/utils';

import { UserData } from '../../../../models/user-data';
import { PlanData } from '../../../../models/plan-data.model';

import { HealthProfileService } from '../../../../services/health-profile.service';
import { AppManagerService } from '../../../../services/app-manager.service';
import { UIService } from '../../../../services/ui.service';
import { UserService } from '../../../../services/user.service';
import { TrackingService } from '../../../../services/tracking.service';
import { UserPlanDataStoreService } from '../../../../services/stores/user-plan-data-store/user-plan-data-store.service';
import { PlanType } from '../../../../models/plan-type.model';
import { HealtheeDialogService } from '../../../../services/healthee-dialog.service';
import { Maybe } from '../../../../utils/types/maybe';
import { MeUserStoreService } from '../../../../services/stores/me-user-store/me-user-store.service';
import { UnleashService } from '../../../../services/unleash.service';
import { FfCustomDigitalCard, FfDigitalCard } from '../../../../config/feature-flags/ff-digital-card';
import { PlanSelectionStoreService } from 'src/app/services/stores/plan-selection-store/plan-selection-store.service';
import { PREVENTIVE_CARE } from '../../../feature-flags/flag-keys';
import { DigitalCardService } from '../../../../services/digital-card/digital-card.service';
import { CompanyStoreService } from '../../../../services/stores/company-store/company-store.service';
import { LoaderColor } from '../../../../../../projects/ripple/src/lib/loader/loader.component';
import { ICompany } from 'src/app/models/company.model';
import { LoggerService } from '../../../../services/logger.service';
import { RxjsUtils } from 'src/app/utils/rxjs';

export const USER_MEMBERSHIP_CARD_LOADING = '/assets/images/health-profile/card_placeholder.svg';
export const USER_MEMBERSHIP_CARD_DEFAULT = '/assets/images/health-profile/upload_card.svg';
export const USER_MEMBERSHIP_CARD_ES = '/assets/images/health-profile/upload_card_es.svg';

enum USER_MEMBERSHIP_STATES {
	LOADING = 'loading',
	DEFAULT = 'default',
	UPLOADED = 'userUploadedCard',
}
enum DIGITAL_CARD_STATES {
	LOADING = 'loading',
	DEFAULT = 'default',
	CUSTOM = 'custom',
}
@Component({
	selector: 'app-personal-info',
	templateUrl: './personal-info.component.html',
	styleUrls: [
		'./personal-info.component.shared.scss',
		useIonicFile('./personal-info.component.scss', './personal-info.component.ionic.scss'),
	],
})
export class PersonalInfoComponent implements OnInit {
	@ViewChild('fileInput')
	fileInputRef: ElementRef;

	@ViewChild('connectForm')
	connectFormRef: TemplateRef<any>;

	cardImageUrl: string = '';

	public openEnrollmentAvailable$: Observable<boolean> = this.planSelectionStoreService.isPlanSelectionEnabled();
	public userCompany: Observable<ICompany> = this.companyStoreService.get();
	public userData$: Observable<UserData>;
	public planData$: Observable<PlanData>;
	public externalCardLink : string = '';
	private server = resolveHost();
	private userMembershipCardEndpoint = this.server + CFG.apiEndpoints.userMembershipCard;
	private isSpanishCard: boolean = false;
	private userDataForUISub: Subscription;
	private digitalCardServiceSub: Subscription;
	public selectedGender: string;
	public isSmoker: string;

	@T('Could not update your membership card. Please try again later.')
	private userErrors_membershipCardUploadFailed: string;

	@T('My Personal Info')
	private mailTitle_contactUs: string;

	@T('Male')
	public maleGender: string;

	@T('Female')
	public femaleGender: string;

	@T('Prefer not to say')
	public otherGender: string;

	@T('Yes')
	public smoker: string;

	@T('No')
	public nonSmoker: string;

	public readonly PlanType = PlanType;

	unlockFormPlanType: Maybe<PlanType>;

	isDigitalCardEnabled$ = this.unleashService.isEnabled$(FfDigitalCard);

	isCustomDigitalCardEnabled$ = this.unleashService.isEnabled$(FfCustomDigitalCard);

	hasMedicalPlanAvailable$: Observable<boolean>;

	hasDentalPlanAvailable$: Observable<boolean>;

	hasVisionPlanAvailable$: Observable<boolean>;

	isMedicalPlanConnected$: Observable<boolean>;

	isDentalPlanConnected$: Observable<boolean>;

	isVisionPlanConnected$: Observable<boolean>;

	showDigitalCard$: Observable<boolean>;

	showPreventiveCareTeaser: boolean = this.unleashService.isEnabled(PREVENTIVE_CARE);

	digitalCardTemplates: any;

	digitalCardState: string;

	hasDigitalCardContent: boolean = true;

	public loaderColor = LoaderColor.yellow;

	public hasWellnessTrackerFeature: boolean = false;

	private companyServiceSub: Subscription;

	public isTrinet: boolean = false;

	constructor(
		private languageService: LanguageService,
		private appManager: AppManagerService,
		private healthProfileService: HealthProfileService,
		private uiService: UIService,
		private userService: UserService,
		private planSelectionStoreService: PlanSelectionStoreService,
		private trackingService: TrackingService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		public healtheeDialogService: HealtheeDialogService,
		private meUserStoreService: MeUserStoreService,
		private unleashService: UnleashService,
		private digitalCardService: DigitalCardService,
		private companyStoreService: CompanyStoreService,
		private cdr: ChangeDetectorRef,
		private logger: LoggerService
	) {}

	ngOnInit(): void {
		this.setCardTemplate();
		this.subscribeToLocale();
		this.setUserMembershipCardImage(USER_MEMBERSHIP_STATES.LOADING);
		this.planData$ = this.userPlanDataStoreService.get();
		this.userData$ = this.userService.userData$.pipe(
			tap((userData: UserData) => {
				userData.hasMembershipCard
					? this.setUserMembershipCardImage(USER_MEMBERSHIP_STATES.UPLOADED)
					: this.setUserMembershipCardImage(USER_MEMBERSHIP_STATES.DEFAULT);

				if (userData.company?.isTalonTpa) {
					this.externalCardLink = 'link'; //TODO: get external card link from talon
				}
			})
		);
		this.updateViewFromUserData();
		this.hasVisionPlanAvailable$ = this.userPlanDataStoreService.hasContractByType('vision');
		this.hasDentalPlanAvailable$ = this.userPlanDataStoreService.hasContractByType('dental');
		this.hasMedicalPlanAvailable$ = this.userPlanDataStoreService.hasContractByType('contract');

		this.isMedicalPlanConnected$ = this.meUserStoreService.hasConnectedContract('medical');
		this.isVisionPlanConnected$ = this.meUserStoreService.hasConnectedContract('vision');
		this.isDentalPlanConnected$ = this.meUserStoreService.hasConnectedContract('dental');

		this.showDigitalCard$ = this.isDigitalCardEnabled$

		if (this.isDigitalCardEnabled$) {
			this.trackingService.trackClientEvent('DMC - View Card');
		}

		this.companyServiceSub = this.companyStoreService
			.get()
			.pipe(
				tap(
					(company) =>
						(this.hasWellnessTrackerFeature =
							company?.showWellnessTracker === undefined || company?.showWellnessTracker)
				)
			)
			.subscribe();

		this.isTrinet = window.location.hostname.includes('trinet');
	}

	ngOnDestroy() {
		this.userDataForUISub?.unsubscribe();
		this.digitalCardServiceSub?.unsubscribe();
		this.companyServiceSub?.unsubscribe();
	}

	private subscribeToLocale() {
		this.languageService.appLanguage$.subscribe((appLanguage: AppLanguage) => {
			if (appLanguage.locale == 'es') {
				this.isSpanishCard = true;
				this.cardImageUrl = USER_MEMBERSHIP_CARD_ES;
			}
		});
	}

	private setUserMembershipCardImage(source: USER_MEMBERSHIP_STATES) {
		switch (source) {
			case USER_MEMBERSHIP_STATES.UPLOADED:
				this.cardImageUrl = this.userMembershipCardEndpoint;
				break;

			case USER_MEMBERSHIP_STATES.LOADING:
				this.cardImageUrl = USER_MEMBERSHIP_CARD_LOADING;
				break;

			case USER_MEMBERSHIP_STATES.DEFAULT:
				this.cardImageUrl = this.isSpanishCard ? USER_MEMBERSHIP_CARD_ES : USER_MEMBERSHIP_CARD_DEFAULT;
				break;
		}
	}

	private updateViewFromUserData() {
		this.userDataForUISub = this.userData$.subscribe((userData) => {
			this.setGender(userData?.gender);
			this.setIsSmoker(userData?.smoking);
		});
	}

	private setGender(userDataGender: string) {
		switch (userDataGender) {
			case 'male':
				this.selectedGender = this.maleGender;
				break;
			case 'female':
				this.selectedGender = this.femaleGender;
				break;
			default:
				this.selectedGender = this.otherGender;
		}
	}

	private setIsSmoker(userDataSmoking: boolean) {
		this.isSmoker = userDataSmoking ? this.smoker : this.nonSmoker;
	}

	openChooseFileDialog() {
		this.fileInputRef.nativeElement.click();
	}

	async fileChanged(filesList: FileList) {
		this.setUserMembershipCardImage(USER_MEMBERSHIP_STATES.LOADING);
		const file = filesList.item(0);

		return this.healthProfileService.updateUserMembershipCard(file).subscribe({
			next: () => this.handleCardUploadSuccess(),
			error: (error) => this.handleCardUploadFailure(error),
		});
	}

	private handleCardUploadSuccess() {
		this.trackingService.trackClientEvent('Health Profile Upload card success');
		this.setUserMembershipCardImage(USER_MEMBERSHIP_STATES.UPLOADED); // change the loading-indicator to the newly uploaded file
	}

	private handleCardUploadFailure(error: Error) {
		this.setUserMembershipCardImage(USER_MEMBERSHIP_STATES.DEFAULT);
		this.uiService.displayAppMessage(this.userErrors_membershipCardUploadFailed);

		console.log('Failed uploading user membership card file. ', error);
	}

	public openContactUs() {
		this.trackingService.trackClientEvent('Health Profile Contact us start');
		this.appManager.openContactUsModal(this.mailTitle_contactUs);
	}

	public openEditInfoModal() {
		this.appManager.openEditInfoModal();
	}

	public getFullImgPath(path: string) {
		return `${this.server}/${path}`;
	}

	openUnlockForm(planType: PlanType) {
		this.unlockFormPlanType = planType;
		this.healtheeDialogService.open({
			templateRef: this.connectFormRef,
			hasCloseButton: true,
			disableClose: false,
		});
		this.trackingService.trackClientEvent('DMC - Unlock Start', { planType });
	}

	unlocked(planType: PlanType) {
		this.healtheeDialogService.close();
		this.trackingService.trackClientEvent('DMC - Unlock Success', { planType });
	}

	private setDigitalCardState = (state: keyof typeof DIGITAL_CARD_STATES) =>
		(this.digitalCardState = DIGITAL_CARD_STATES[state]);
	private setCardTemplate = () =>
		(this.digitalCardServiceSub = this.isCustomDigitalCardEnabled$
			.pipe(take(1))
			.pipe(
				switchMap((isEnabled) => {
					this.logger.info(`setCardTemplate check for isCustomDigitalCardEnabled$ = ${isEnabled}`);
					if (isEnabled) {
						this.logger.info('setCardTemplate start loading state');
						this.setDigitalCardState('LOADING');
						return this.companyStoreService
							.get()
							.pipe(filter((value) => !!value))
							.pipe(
								switchMap((company) => {
									this.logger.info(`setCardTemplate got company ${company._id}`);
									const customCardType = this.digitalCardService.getCustomCardType(company);
									this.logger.info(`setCardTemplate got customCardType ${customCardType}`);
									if (customCardType) {
										this.logger.info(`setCardTemplate before calling this.digitalCardService
										.getTemplate`);
										return this.digitalCardService
											.getTemplate(customCardType)
											.pipe(
												map((res) => {
													this.logger.info(`setCardTemplate before settings state to CUSTOM`);
													this.digitalCardTemplates = res;
													this.setDigitalCardState('CUSTOM');
													this.hasDigitalCardContent = true;
												})
											)
											.pipe(
												catchError((error) => {
													this.cdr.detectChanges();
													this.hasDigitalCardContent = false;
													this.logger.error(
														'setCardTemplate - error fetching custom card template',
														error
													);
													return throwError(() => error);
												})
											);
									} else {
										this.logger.info(
											`setCardTemplate before settings state to DEFAULT because no customCardType found`
										);
										this.setDigitalCardState('DEFAULT');
										this.hasDigitalCardContent = true;
										return of(EMPTY);
									}
								})
							)
							.pipe(
								catchError((error) => {
									this.logger.error('setCardTemplate - error fetching company store', error);
									return throwError(() => error);
								})
							);
					} else {
						this.logger.info(`setCardTemplate before settings state to DEFAULT because isEnabled is false`);
						this.setDigitalCardState('DEFAULT');
						this.hasDigitalCardContent = true;

						return of(EMPTY);
					}
				})
			)
			.subscribe());
}
