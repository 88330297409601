import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { AppManagerService } from '../../../../services/app-manager.service';
import { DeductiblesApiService } from '../../../../services/api/deductibles-api/deductibles-api.service';
import { StandardLoadingHandler } from '../../../../utils/loading-handler/standard-loading-handler';
import { T } from '@transifex/angular';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackingService } from '../../../../services/tracking.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { pluck, switchMap, take } from 'rxjs';
import { UIService } from '../../../../services/ui.service';
import { MeUserStoreService } from '../../../../services/stores/me-user-store/me-user-store.service';
import { RxjsUtils } from '../../../../utils/rxjs';
import { PlanSelectionStoreService } from '../../../../services/stores/plan-selection-store/plan-selection-store.service';

@Component({
	selector: 'app-connect-deductibles-form-handler',
	templateUrl: './connect-deductibles-form-handler.component.html',
	styleUrls: ['./connect-deductibles-form-handler.component.scss'],
})
export class ConnectDeductiblesFormHandlerComponent implements OnInit {
	@Output()
	connected = new EventEmitter<void>();

	@ViewChild('memberID')
	memberIDFormControl: ElementRef<any>;

	@T('Medical plan successfully connected')
	successMessage: string;

	@T('Something went wrong, we will investigate and let you know once it’s solved.')
	unknownErrorMessage: string;

	@T('Incorrect "Member Id" not found by the insurance partner')
	invalidSubscriberId: string;

	@T('Incorrect name not found by the insurance partner')
	invalidSubscriberName: string;

	@T('Incorrect member information not found by the insurance partner')
	invalidSubscriber: string;

	@T('Incorrect "birth date" not found by the insurance partner')
	invalidSubscriberBirthDate: string;

	@T(
		'The insurance partner is experiencing temporary downtime and is unable to complete the request, please try again later.'
	)
	payerUnableToRespondNow: string;

	@T('The insurance partner is unable to respond, which will be investigated and resolved.')
	payerUnableToRespond: string;

	@T('The insurance partner had an error, which will be investigated and resolved.')
	unableToRespond: string;

	@T('Fields returned by the payer are invalid so we are unable to relay the information in the response.')
	invalidPayerResponse: string;

	saveLoadingHandler = new StandardLoadingHandler();

	public isTrinet: boolean = false;

	form = new FormGroup({
		memberId: new FormControl(null, [Validators.required]),
		dateOfBirth: new FormControl(null, []),
		month: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(12)]),
		day: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(31)]),
		year: new FormControl(null, [Validators.required, Validators.max(dayjs().year())]),
	});

	constructor(
		public appManagerService: AppManagerService,
		private deductiblesApiService: DeductiblesApiService,
		private trackingService: TrackingService,
		private userService: UserService,
		private matSnackBar: MatSnackBar,
		private router: Router,
		private uiService: UIService,
		private meUserStoreService: MeUserStoreService,
		private planSelectionStoreService: PlanSelectionStoreService,
	) {}

	ngOnInit(): void {
		this.userService.userData$.pipe(take(1), pluck('birthday')).subscribe((birthday) => {
			this.form.patchValue({
				month: dayjs(birthday).month() + 1,
				day: dayjs(birthday).date(),
				year: dayjs(birthday).year(),
			});
		});

		this.planSelectionStoreService
		.get()
		.pipe(RxjsUtils.isNotNil(), take(1))
		.subscribe((data) => {
			this.isTrinet = data.companyData.isTriNet;
		});
	}

	connect() {
		this.saveLoadingHandler.start();
		const { memberId, month, day, year } = this.form.value;
		const birthdayStr = dayjs()
			.set('year', Number(year))
			.set('month', Number(month) - 1)
			.set('date', Number(day))
			.format('YYYY-MM-DD');

		const dateOfBirth = new Date(birthdayStr + ' 12:00:00');

		this.userService.updateUserProfileFromFormData({ dateOfBirth }).subscribe();
		this.deductiblesApiService
			.connect({
				memberId,
				birthDate: birthdayStr,
			})
			.subscribe({
				next: () => {
					this.matSnackBar.open(this.successMessage, 'Ok', {
						duration: 5000,
						panelClass: 'snackbar-success',
						verticalPosition: 'top',
					});
					this.saveLoadingHandler.finish();
					this.connected.emit();
					this.track('Deductible Connection Success', { Source: this.router.url });
				},
				error: (err) => {
					const { message, isUnknown } = this.errorMessage(err);
					this.uiService.displayAppMessage(message);
					if (isUnknown) {
						this.meUserStoreService
							.get()
							.pipe(
								RxjsUtils.isNotNil(),
								take(1),
								switchMap((user) =>
									this.userService.sendContactUsEmail({
										subject: 'Deductible connection error',
										text: `User: ${user.firstName} ${
											user.lastName
										} <br> Original error: <br> ${JSON.stringify(err?.originalError?.error)}`,
									})
								)
							)
							.subscribe({});
					}
					this.saveLoadingHandler.finish();
					this.track('Deductible Connection Failed', { Source: this.router.url, err });
				},
			});
	}

	public track(event, metaData = {}): void {
		this.trackingService.trackClientEvent(event, metaData);
	}

	private errorMessage(err: any): { message: string; isUnknown: boolean } {
		const errorCode = err?.originalError?.error?.errorCode;
		if (!errorCode)
			return {
				message: this.unknownErrorMessage,
				isUnknown: true,
			};
		let message: string;
		let isUnknown: boolean = false;

		switch (errorCode) {
			case 'invalid_subscriber_id':
				message = this.invalidSubscriberId;
				break;
			case 'invalid_subscriber_name':
				message = this.invalidSubscriberName;
				break;
			case 'invalid_subscriber':
				message = this.invalidSubscriber;
				break;
			case 'invalid_subscriber_birth_date':
				message = this.invalidSubscriberBirthDate;
				break;
			case 'payer_unable_to_respond_now':
				message = this.payerUnableToRespondNow;
				break;
			case 'payer_unable_to_respond':
				message = this.payerUnableToRespond;
				break;
			case 'unable_to_respond':
				message = this.unableToRespond;
				break;
			case 'invalid_payer_response':
				message = this.invalidPayerResponse;
				break;
			default:
				message = this.unknownErrorMessage;
				isUnknown = true;
		}
		return {
			message,
			isUnknown,
		};
	}
}
