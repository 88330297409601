<div class="providers-map-card">
	<div #cardElement>
		<div class="pointer-card" *ngIf="!!selectedFacility || !!selectedProvider">
			<div class="slider-btns" *ngIf="sameLocationIndexes?.length > 1 && (!!selectedFacility || !!selectedProvider)">
				<div class="provider-counter">
					<strong>{{getSliderButtonsActiveIndex() + 1}}</strong>
					of
					<strong>{{sameLocationIndexes?.length}}</strong>
					Providers
				</div>
				<ripple-slider-buttons
					class="slider-btns-space-between"
					[activeIndex]="getSliderButtonsActiveIndex()"
					[minIndex]="0"
					[maxIndex]="sameLocationIndexes.length"
					[isSpaceBetween]="true"
					(setNewIndex)="onLocationChanged($event)"
				></ripple-slider-buttons>
			</div>
			<app-provider-card
				[class.map]="true"
				*ngIf="!!selectedProvider"
				[providerData]="selectedProvider"
				(openScheduleAppointment)="openCreateAppointment.emit(selectedProvider)"
				[routerLink]="[providersSearchUrlsService.specialistUrl, selectedProvider.npi]"
				[isFavorite]="isFavorite$ | async"
				(isFavoriteChange)="onIsFavoriteChange($event, selectedProvider)"
				eventSource="Detail Page"
			>
			</app-provider-card>

			<app-facility-card
				*ngIf="!!selectedFacility"
				[facility]="selectedFacility"
				[isFavorite]="false"
				[routerLink]="[providersSearchUrlsService.facilityUrl, selectedFacility.uuid]"
			>
			</app-facility-card>
		</div>
	</div>
</div>
