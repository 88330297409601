<div class="health-profile-container" *ngIf="userData$ | async as userData">
	<div *ngIf="(showDigitalCard$ | async) && hasDigitalCardContent" class="healthee-profile-cards">
		<div class="healthee-profile-cards-title">
			{{ 'Member ID Cards' | translate }}
			<app-healthee-mark-tooltip
				[mobileTitle]="'Digital cards' | translate"
				[text]="
					'A digital insurance card is an electronic version of a traditional physical insurance card that can be accessed through a mobile device, making it more convenient and easily updatable.'
						| translate
				"
			></app-healthee-mark-tooltip>
		</div>
		<app-healthee-content-slider [hideArrows]="true" [enableClickScroll]="false">
			<ng-container [ngSwitch]="digitalCardState">
				<div *ngSwitchCase="'loading'" class="loader">
					<app-loader [color]="loaderColor"></app-loader>
				</div>
				<app-custom-digital-card
					*ngSwitchCase="'custom'"
					[digitalCardTemplates]="digitalCardTemplates"
				></app-custom-digital-card>
				<ng-container *ngSwitchCase="'default'">
					<ng-container
						[ngTemplateOutlet]="cardDisplay"
						[ngTemplateOutletContext]="{
							contract: (planData$ | async)?.contract,
							isPlanConnected: isMedicalPlanConnected$ | async,
							isPlanAvailable: hasMedicalPlanAvailable$ | async,
							planType: PlanType.medical
						}"
					></ng-container>

					<ng-container
						[ngTemplateOutlet]="cardDisplay"
						[ngTemplateOutletContext]="{
							contract: (planData$ | async)?.dental,
							isPlanConnected: isDentalPlanConnected$ | async,
							isPlanAvailable: hasDentalPlanAvailable$ | async,
							planType: PlanType.dental
						}"
					></ng-container>

					<ng-container
						[ngTemplateOutlet]="cardDisplay"
						[ngTemplateOutletContext]="{
							contract: (planData$ | async)?.vision,
							isPlanConnected: isVisionPlanConnected$ | async,
							isPlanAvailable: hasVisionPlanAvailable$ | async,
							planType: PlanType.vision
						}"
					></ng-container>
				</ng-container>
			</ng-container>
		</app-healthee-content-slider>
	</div>

	<div *ngIf="(showDigitalCard$ | async) === false" class="sidepanel">
		<div class="holder-details">
			<div class="name">{{ userData.firstName + ' ' + userData.lastName }}</div>
			<div *ngIf="userCompany | async as company">
				<div *ngIf="company.logoFileName">
					<img class="company-logo" [src]="company.logoFileName | companyImage" [alt]="company.name"/>
				</div>
			</div>
		</div>

		<div class="insucard-wrapper">
			<div class="insucard" (click)="openChooseFileDialog()">
				<input
					#fileInput
					class="fileInput"
					type="file"
					accept="image/png,image/jpeg,image/jpg"
					(change)="fileChanged($event.target.files)"
				/>
				<img class="image" [src]="cardImageUrl | useHttpImgSrc" />
			</div>
		</div>
	</div>
	<div>
		<app-plan-selection-button
			*ngIf="openEnrollmentAvailable$ | async"
			source="health-profile"
		></app-plan-selection-button>

		<div *ngIf="showPreventiveCareTeaser && hasWellnessTrackerFeature" class="preventive-care-teaser-wrapper">
			<app-preventive-care-teaser [showInitTeaser]="false" source="Profile"></app-preventive-care-teaser>
		</div>
		<div class="my-plan-info" *ngIf="planData$ | async as planData">
			<div class="personal-details" *ngIf="planData.contract?.insuranceCompany?.name">
				<div class="header">
					<div class="title">
						<img src="/assets/images/icons/switch-contract/contract.svg" />
						{{ 'My Medical Plan Info' | translate }}
					</div>
					<!-- (click)="downloadSBC(planData.contract.sbcUrl)" -->
					<a *ngIf="!userData.company?.isTalonTpa && planData.contract.sbcUrl as sbcURL" target="_blank" href="https://assets.healthee.co/contracts_sbc/{{sbcURL}}" class="sbc-download">
						<div class="download-link">{{'Download SBC' | translate}}</div>
						<ripple-icon [name]="'download'"></ripple-icon>
					</a>
				</div>
				<div class="row-wrapper">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/health-insurance-provider.svg"
							alt="health-insurance-provider.svg"
						/>
						<div class="key"><UT str="Health Insurance Provider" key="health_insurance_provider"></UT></div>
					</div>
					<div class="value">{{ planData.contract.insuranceCompany.name ?? 'N/A' }}</div>
				</div>
				<div class="row-wrapper" *ngIf="planData.contract.planCareType">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/plan-type-icon.svg"
							alt=""
						/>
						<div class="key"><UT str="Plan Type" key="plan_type"></UT></div>
					</div>
					<div class="value planType">{{ planData.contract.planCareType.toUpperCase() }}</div>
				</div>
				<div *ngIf="planData.contract.name" class="row-wrapper">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/plan-name-icon.svg"
							alt="plan-name-icon"
						/>
						<div class="key"><UT str="Plan Name" key="plan_name"></UT></div>
					</div>
					<div class="value">{{ planData.contract.name ?? 'N/A' }}</div>
				</div>
				<div *ngIf="planData.contract.valid_until" class="row-wrapper">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/valid-until-icon.svg"
							alt="valid-until-icon"
						/>
						<div class="key"><UT str="Valid Until" key="valid_until"></UT></div>
					</div>
					<div class="value planType">{{ planData.contract.valid_until }}</div>
				</div>
			</div>

			<div class="personal-details" *ngIf="planData.dental?.insuranceCompany?.name">
				<div class="header">
					<div class="title">
						<img src="/assets/images/icons/switch-contract/dental.svg" />
						{{ 'My Dental Plan Info' | translate }}
					</div>

					<a *ngIf="planData.dental.sbcUrl as sbcURL" target="_blank" href="https://assets.healthee.co/contracts_sbc/{{sbcURL}}" class="sbc-download">
						<div class="download-link">{{'Download SBC' | translate}}</div>
						<ripple-icon [name]="'download'"></ripple-icon>
					</a>
				</div>
				<div class="row-wrapper">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/health-insurance-provider.svg"
							alt="health-insurance-provider.svg"
						/>
						<div class="key"><UT str="Health Insurance Provider" key="health_insurance_provider"></UT></div>
					</div>
					<div class="value">{{ planData.dental.insuranceCompany.name ?? 'N/A' }}</div>
				</div>
				<div class="row-wrapper" *ngIf="planData.dental.planCareType">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/plan-type-icon.svg"
							alt=""
						/>
						<div class="key"><UT str="Plan Type" key="plan_type"></UT></div>
					</div>
					<div class="value planType">{{ planData.dental.planCareType.toUpperCase() }}</div>
				</div>
				<div *ngIf="planData.dental.name" class="row-wrapper">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/plan-name-icon.svg"
							alt="plan-name-icon"
						/>
						<div class="key"><UT str="Plan Name" key="plan_name"></UT></div>
					</div>
					<div class="value">{{ planData.dental.name ?? 'N/A' }}</div>
				</div>
				<div *ngIf="planData.dental.valid_until" class="row-wrapper">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/valid-until-icon.svg"
							alt="valid-until-icon"
						/>
						<div class="key"><UT str="Valid Until" key="valid_until"></UT></div>
					</div>
					<div class="value planType">{{ planData.dental.valid_until }}</div>
				</div>
			</div>

			<div class="personal-details" *ngIf="planData.vision?.insuranceCompany?.name">
				<div class="header">
					<div class="title">
						<img src="/assets/images/icons/switch-contract/vision.svg" />
						{{ 'My Vision Plan Info' | translate }}
					</div>
					<a *ngIf="planData.vision.sbcUrl as sbcURL" target="_blank" href="https://assets.healthee.co/contracts_sbc/{{sbcURL}}" class="sbc-download">
						<div class="download-link">{{'Download SBC' | translate}}</div>
						<ripple-icon [name]="'download'"></ripple-icon>
					</a>
				</div>
				<div class="row-wrapper">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/health-insurance-provider.svg"
							alt="health-insurance-provider.svg"
						/>
						<div class="key"><UT str="Health Insurance Provider" key="health_insurance_provider"></UT></div>
					</div>
					<div class="value">{{ planData.vision.insuranceCompany?.name ?? 'N/A' }}</div>
				</div>
				<div class="row-wrapper" *ngIf="planData.vision.planCareType">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/plan-type-icon.svg"
							alt=""
						/>
						<div class="key"><UT str="Plan Type" key="plan_type"></UT></div>
					</div>
					<div class="value planType">{{ planData.vision.planCareType.toUpperCase() }}</div>
				</div>
				<div *ngIf="planData.vision?.name" class="row-wrapper">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/plan-name-icon.svg"
							alt="plan-name-icon"
						/>
						<div class="key"><UT str="Plan Name" key="plan_name"></UT></div>
					</div>
					<div class="value">{{ planData.vision.name ?? 'N/A' }}</div>
				</div>
				<div *ngIf="planData.vision.valid_until" class="row-wrapper">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/valid-until-icon.svg"
							alt="valid-until-icon"
						/>
						<div class="key"><UT str="Valid Until" key="valid_until"></UT></div>
					</div>
					<div class="value planType">{{ planData.vision.valid_until }}</div>
				</div>
			</div>

			<div *ngIf="!isTrinet" class="personal-details">
				<div class="header">
					<div class="title">{{ ' My Personal Info' | translate }}</div>
					<div class="edit" (click)="openEditInfoModal()">
						{{ 'Edit info' | translate }}
						<mat-icon>edit</mat-icon>
					</div>
				</div>
				<div class="row-wrapper">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/birthday-icon.svg"
							alt=""
						/>
						<div class="key"><UT str="Date of Birth" key="birth_date"></UT></div>
					</div>
					<div class="value">{{ userData.birthday | date }}</div>
				</div>
				<div class="row-wrapper">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/gender-icon.svg"
							alt=""
						/>
						<div class="key">{{ 'Gender' | translate }}</div>
					</div>
					<div class="value">{{ selectedGender ?? 'N/A' }}</div>
				</div>
				<div class="row-wrapper">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/weight-icon.svg"
							alt=""
						/>
						<div class="key"><UT str="Weight" key="weight"></UT></div>
					</div>
					<div class="value">{{ userData.weight ?? 'N/A' }} lbs.</div>
				</div>
				<div class="row-wrapper">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/height-icon.svg"
							alt=""
						/>
						<div class="key"><UT str="Height" key="height"></UT></div>
					</div>
					<div class="value">{{ userData.height ?? 'N/A' }}</div>
				</div>
				<div class="row-wrapper" *ngIf="userData.smoking !== undefined">
					<div class="key-wrapper">
						<img
							class="key-icon"
							src="../../../../../assets/images/icons/personal-info/smoker-icon.svg"
							alt=""
						/>
						<div class="key">{{ 'Smoker' | translate }}</div>
					</div>
					<div class="value">{{ isSmoker ?? 'N/A' }}</div>
				</div>
			</div>
		</div>
	</div>
	<span class="email-us-contaienr"
		>{{ '*If any of the information on this page is incorrect,' | translate }}
		<button class="email-us" (click)="openContactUs()">{{ 'let us know' | translate }}</button>
		{{ "and we'll fix it." | translate }}
	</span>
</div>

<ng-template
	#cardDisplay
	let-contract="contract"
	let-isPlanConnected="isPlanConnected"
	let-isPlanAvailable="isPlanAvailable"
	let-planType="planType"
>
	<ng-container *ngIf="isPlanAvailable">
		<app-full-card
			*ngIf="isPlanConnected"
			[firstName]="(userData$ | async)?.firstName"
			[lastName]="(userData$ | async)?.lastName"
			[contract]="contract"
			[externalCardLink]="externalCardLink"
			(unlockClick)="openUnlockForm($event)"
		></app-full-card>
		<app-locked-card
			*ngIf="!isPlanConnected"
			(unlockClick)="openUnlockForm($event)"
			[contract]="contract"
		></app-locked-card>
	</ng-container>
	<app-empty-card [planType]="planType" *ngIf="!isPlanAvailable"></app-empty-card>
</ng-template>

<ng-template #connectForm>
	<app-connect-digital-card-form-handler
		(unlocked)="unlocked(unlockFormPlanType)"
		[planType]="unlockFormPlanType"
	></app-connect-digital-card-form-handler>
</ng-template>
