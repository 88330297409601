import { Component, HostListener, OnInit } from '@angular/core';
import { TrackingService } from '../../../../services/tracking.service';
import CFG from '../../../../config/app-config.json';
import { IntercomService } from '../../../../services/intercom.service';

@Component({
	selector: 'app-plan-selection-teaser',
	templateUrl: './plan-selection-teaser.component.html',
	styleUrls: ['./plan-selection-teaser.component.scss'],
})
export class PlanSelectionTeaserComponent implements OnInit {
	@HostListener('click', ['$event']) onClick() {
		this.track();
	}
	planSelectionPath: string;
	constructor(private trackingService: TrackingService, private intercomService: IntercomService) {}

	ngOnInit(): void {
		this.planSelectionPath = this.getPlanSelectionPath();
	}

	getPlanSelectionPath() {
		return '/' + CFG.openEnrollmentRoute;
	}

	track() {
		this.trackingService.trackClientEventPCT('Start', { source: 'teaser' });
	}
}
